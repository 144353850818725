import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import BeforeAfterSlider from "../../../components/react-before-after-slider";
import locales from "../../../constants";

const YorkSchoolOfEnglish = ({ pageContext: { slug } }) => {
  const lang = "en";
  const nav = {
    initialSlideIndex: 1,
  };
  const before = require("../../../assets/img/portfolio/york_laptop_old.png");
  const after = require("../../../assets/img/portfolio/york_laptop_new.png");
  return (
    <Layout
      seo={{
        title: "York School of English",
        href: slug,
        lang: "en",
      }}
      header={{
        background:
          "linear-gradient( 45deg, #B91914, #CC1B15, #B91914, #CC1B15, #B91914 )",
        icons: "#a7110c",
        navClass: "york",
        ogImage: require("../../../assets/img/portfolio/york_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/york-school-of-english/",
      }}
    >
      <PortfolioHeader name="york" height="260" />
      <section className="container-fluid york_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>
                York <br /> School of English
              </h1>
              <ul>
                <li>Website</li>
                <li>Lead Campaing</li>
                <li>Banner Ads</li>
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div className="inner">
              <p>
                We started cooperation with the York School of English in 2016.
                The Client came to us with the need to create a new website on
                the basis of its current one, which in his opinion was not
                intuitive enough, and, therefore, quite useless for the users.
                Our task was to help the school so that its current and future
                students could learn about the full scope of ​​the school's
                activity in an easy, friendly, and understandable way. Our first
                and basic task was to process the full sitemap, which in its
                many nests contained over 70 subpages. Take a look at how many
                categories and subpages were there on the site
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid york_section_laptop">
        <img
          className="img-fluid img_non_mobile"
          src={require("../../../assets/img/portfolio/york_laptop.png")}
          alt=""
        />
        <div className="york_section_laptop-wrapper">
          <BeforeAfterSlider
            before={before}
            after={after}
            width={957}
            height={599}
          />
        </div>
        <img
          className="img-fluid img_mobile"
          src={require("../../../assets/img/portfolio/york_laptop_mobile.png")}
          alt=""
        />
      </section>

      <section className="container-fluid york_section_3">
        <div className="row row_1">
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/york_main_1.jpg")}
              alt=""
            />
          </div>
          <div className="col-md-6">
            <div className="inner">
              <h3>Website</h3>
              <p>
                In the first phase, we focused on which pages are necessary,
                which we can connect together, and which of them are outdated,
                and require removal, or reformatting. Traffic analysis showed
                that the current website has a high bounce rate - this suggested
                that users feel lost and do not know where to find the
                information they need - at the same time they spent quite a lot
                of time on it, which in turn showed that they were trying to
                find important information from their perspective. Thanks to
                this analysis, we also knew which websites are the most
                important for customers, how much time they spend on it, and how
                they look for information.
              </p>
              <p>
                After the initial "slimming" of the site, we created a layout
                that focused on three areas: improving usability, reducing the
                number of nests, and giving access to the most important
                information from the main page to the users. We consulted the
                layout with the Client to confirm some of our assumptions with
                the specificity of their business and the characteristics of the
                target group. This is a particularly important stage of
                cooperation.
              </p>
            </div>
          </div>
        </div>
        <div className="row no-gutters row_2">
          <div className="col-md-6">
            <div className="inner">
              <p>
                After our Client accepted the layout we created, which in the
                structure of the page allowed us to reduce the number of
                subpages by more than 30%, without losing any relevant
                information, we proceeded to the stage of graphic design. Here,
                our main task was, in turn, to reduce the amount of content and
                to present a large part in the form of graphics and slogans -
                this was to provide the users with a quick way to find the
                information they were actually looking for, while informing them
                about other fields of school activities...
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/york_main_2.jpg")}
              alt=""
            />
          </div>
        </div>
        <div className="row no-gutters row_3">
          <div className="col-md-6 offset-md-3">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/york_main_3.jpg")}
              alt=""
            />
          </div>
        </div>
        <div className="row no-gutters row_4">
          <div className="col-md-3">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/york_main_4.jpg")}
              alt=""
            />
          </div>
          <div className="col-md-5 offset-md-3">
            <div className="inner">
              <h3>Internet Marketing</h3>
              <p>
                WHEN has carried out two large recruitment campaigns aimed at
                attracting training participants for the 2016/2017 and 2017/2018
                school years. An additional goal was to encourage the users to
                solve classification tests for the level groups in order to
                properly assign them according to their current knowledge. The
                campaigns were intensively ran from August to October 2016 and
                in the same period in 2017.
              </p>
            </div>
          </div>
        </div>
        <div className="row no-gutters row_5">
          <div className="col-md-6">
            <div className="inner">
              <p>
                Recruitment campaigns were conducted in Google AdWords and
                Facebook Ads - separately for each of the target groups. The
                target groups to which we addressed messages were: adults,
                students, young people, and children. Campaign ads dedicated to
                specific groups referred users to subpages with relevant course
                offers. In addition, advertising activities were reinforced with
                remarketing ads, reaching once again the users who were already
                interested in the offer. The campaign was directed within a
                radius of 20 km from the company's headquarters, to people who
                live in the area, or often reside in it. Our Client was pleased
                with the results. The sources of Facebook Ads and Google AdWords
                generated an overwhelming number of sessions against the organic
                source, direct or other inputs (e.g. referral links). There were
                several dozen conversions defined as going to the tab with
                tests, checking price lists of courses, and checking forms of
                contact with the school.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/york_main_5.png")}
              alt=""
            />
          </div>
        </div>
        <div className="row no-gutters row_6">
          <div className="col-md-5">
            <img
              className="img-fluid"
              src={require("../../../assets/img/portfolio/york_main_6.jpg")}
              alt=""
            />
          </div>
          <div className="col-md-6">
            <div className="inner">
              <h3>Design Projects</h3>
              <p>
                Outdoor banners design made by Studio Leon also contributed to
                the campaign's success. We have made sure that they are visually
                consistent with the new website, so they do not confuse users,
                causing a smooth and intuitive transition to the landing pages
                of the campaign. Studio Leon has designed both Internet and
                outdoor banners.
              </p>
              <p>Companies from the ADream Group involved in the project:</p>
              <ul className="list_companies">
                <li>
                  <a
                    href="https://zensite.pl/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="img-fluid"
                      src={require("../../../assets/img/agency/zensite_gray.svg")}
                      alt=""
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://when.pl/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="img-fluid"
                      src={require("../../../assets/img/agency/when_gray.svg")}
                      alt=""
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://studioleon.pl/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="img-fluid"
                      src={require("../../../assets/img/agency/studio_leon_gray.svg")}
                      alt=""
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default YorkSchoolOfEnglish;
